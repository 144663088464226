import React from 'react';
import get from 'lodash.get';

import urls from 'helpers/url';

import {
  Slider,
  SliderTools,
  SharedStyles,
  Container,
} from '@matchbox-mobile/kenra-storybook';

const { StSliderTools } = SliderTools;
const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles;

export default function StylistTools({ tools, count }) {
  return (
    <StSectionMobileViewMore>
      <StSectionTitle>
        <Container>
          <h2>STYLIST TOOLS</h2>
          <StLink mobileStyleLinkMore>
            <a href={urls.tools}>{`VIEW ALL (${
              count ? count : tools.length
            })`}</a>
          </StLink>
        </Container>
      </StSectionTitle>
      <Slider settings={{ slidesPerView: 'auto' }}>
        {tools.map((manual, index) => (
          <StSliderTools key={index}>
            <img
              src={get(manual, 'image.localFile.square.resize.src')}
              alt=""
            />
            <h3>{manual.title}</h3>
            <StLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={get(manual, 'file.localFile.publicURL')}
              >
                Download
              </a>
            </StLink>
          </StSliderTools>
        ))}
      </Slider>
    </StSectionMobileViewMore>
  );
}
