import React from 'react';
import get from 'lodash.get';

import urls from 'helpers/url';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';

import {
  Container,
  SharedStyles,
  VideoWrapList,
} from '@matchbox-mobile/kenra-storybook';

const { StLinkMore, StSectionTitle, StLink } = SharedStyles;

export default function Videos(props) {
  let { title, showBottomButton, videos, bgImage } = props;
  videos = videos.slice(0, 3);

  return (
    <>
      {title && (
        <StSectionTitle>
          <Container>
            <h2>{title}</h2>
            <StLink mobileHide>
              <a href={urls.videos}>VIEW ALL VIDEOS</a>
            </StLink>
          </Container>
        </StSectionTitle>
      )}

      <Container>
        <VideoWrapList
          showBtnMoreDesktop={!!showBottomButton}
          linkMobileText="Watch More"
          linkMobileMore={urls.videos}
          bgUrl={
            bgImage ||
            'https://images.ctfassets.net/6ntd78431879/iPPIvMVgj2A9BJ5e15Evh/0a1f1686bf91eef0bc0392e9bf1f1fb9/Kenra_finish3__1_.jpg'
          }
          items={videos.map(video => {
            let youtubeId = getYouTubeId(video.youtubeLink);

            return {
              img: getPreviewUrl(youtubeId),
              title: get(video, 'title.title'),
              // TODO:
              // subtitle: '54k views — 2 weeks ago',
              videoType: 'youtube',
              autoPlay: true,
              videoOptions: [
                {
                  src: getEmbedUrl(youtubeId),
                },
              ],
            };
          })}
        >
          <StLinkMore>
            <a href={urls.videos}>WATCH MORE</a>
          </StLinkMore>
        </VideoWrapList>
      </Container>
    </>
  );
}
