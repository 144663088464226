import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Education from 'components/Education';

export default function EducationPage(props) {
  let {
    data: {
      allContentfulEvent: { nodes: events },
      contentfulEducationPage,
      allContentfulTool: { nodes: tools },
    },
  } = props;

  return (
    <Layout>
      <Education
        page={contentfulEducationPage}
        events={events}
        allTools={tools}
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulEducationPage {
      title
      text {
        json
      }
      tools {
        ...ContentfulToolFields
      }
    }
    allContentfulEvent {
      nodes {
        name
        image {
          localFile {
            publicURL
          }
        }
        date
        shortDescription {
          shortDescription
        }
      }
    }
    allContentfulTool {
      nodes {
        ...ContentfulToolFields
      }
    }
  }
`;
