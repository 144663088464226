import React, { useState } from 'react';
import { parseISO, format } from 'date-fns';
import get from 'lodash.get';

import { sortByDate } from 'helpers/sort';

import {
  SharedStyles,
  Container,
  Events,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionTitle, StLink } = SharedStyles;

export default function EducationEvents({ events }) {
  let [sortAsc, setSortAsc] = useState(true);

  if (!events || events.length <= 0) {
    return null;
  }

  let now = new Date();
  let filteredEvents = events.filter(event => {
    let date = new Date(event.date);
    // Event already passed
    if (date - now < 0) return false;
    return true;
  });

  if (!filteredEvents || filteredEvents.length <= 0) {
    return null;
  }

  let sortedEvents = sortByDate(filteredEvents, true);
  let [closestEvent, ...otherEvents] = sortedEvents;

  otherEvents = sortByDate(otherEvents, sortAsc).slice(0, 4);

  return (
    <Container>
      <section>
        <StSectionTitle>
          <Container>
            <h2>Upcoming Shows & Events</h2>
            <StLink mobileHide>
              {/* TODO: */}
              <a href="/">VIEW ALL ({filteredEvents.length})</a>
            </StLink>
          </Container>
        </StSectionTitle>
        <Events
          mainItem={{
            img: get(closestEvent, 'image.localFile.publicURL'),
            type: 'UPCOMING',
            typeColor: '#aa58b8',
            title: closestEvent.name,
            text: `<p>${get(
              closestEvent,
              'shortDescription.shortDescription'
            )}</p>`,
            // TODO: link to event
            moreLink: '/',
            moreText: 'More',
          }}
          onSortChange={() => setSortAsc(!sortAsc)}
          startOn={format(parseISO(closestEvent.date), 'MMMM d, Y')}
          items={otherEvents.map(event => ({
            img: get(event, 'image.localFile.publicURL'),
            title: event.name,
            dayWeek: format(parseISO(event.date), 'E'),
            date: format(parseISO(event.date), 'MMMM d, Y'),
            time: format(parseISO(event.date), 'h:mma'),
          }))}
        />
      </section>
    </Container>
  );
}
