import React from 'react';
import get from 'lodash.get';

import urls from 'helpers/url';

import {
  Slider,
  SliderTools,
  SharedStyles,
  Container,
} from '@matchbox-mobile/kenra-storybook';

const { StSliderTools } = SliderTools;
const { StSectionMobileViewMore, StSectionTitle, StLink } = SharedStyles;

export default function StylistTools({ tools, count }) {
  return (
    <StSectionMobileViewMore>
      <StSectionTitle>
        <Container>
          <h2>APPLICATION CARDS</h2>
          <StLink mobileStyleLinkMore>
            <a href={urls.applications}>{`VIEW ALL (${
              count ? count : tools.length
            })`}</a>
          </StLink>
        </Container>
      </StSectionTitle>
      <Slider settings={{ slidesPerView: 'auto' }}>
        {tools.map((application, index) => (
          <StSliderTools key={index}>
            <a href={`/application/${application.title}`}>
              <img
                src={get(application, 'image.localFile.square.resize.src')}
                alt=""
              />
              <h3>{application.title}</h3>
            </a>
          </StSliderTools>
        ))}
      </Slider>
    </StSectionMobileViewMore>
  );
}
