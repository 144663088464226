import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LatestVideos from 'blocks/LatestVideos';
import StylistTools from 'blocks/StylistTools';
import ApplicationCards from 'blocks/ApplicationCards';
import EducationEvents from './EducationEvents';

import {
  Spacing,
  SharedStyles,
  Container,
} from '@matchbox-mobile/kenra-storybook';
const { StSectionInfo, StTitle } = SharedStyles;

const APPLICATION_CARD_TYPE = 'Application card';
const COLOR_MANUAL_TYPE = 'Color manual';

export default function Education({ page, events, allTools }) {
  let cards = [];
  let manuals = [];
  const { tools } = page;
  if (tools) {
    cards = tools.filter(tool => tool.type === APPLICATION_CARD_TYPE);
    manuals = tools.filter(tool => tool.type === COLOR_MANUAL_TYPE);
  }
  let allCards = [];
  let allManuals = [];
  if (allTools) {
    allCards = allTools.filter(tool => tool.type === APPLICATION_CARD_TYPE);
    allManuals = allTools.filter(tool => tool.type === COLOR_MANUAL_TYPE);
  }
  const toolsCount = allManuals.length;
  const applicationsCount = allCards.length;

  return (
    <>
      <Spacing removeSpaceBottom>
        <StSectionInfo>
          <Container>
            <StTitle>
              <h1>{page.title}</h1>
            </StTitle>
            {documentToReactComponents(page.text.json)}
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing>
        <LatestVideos showBottomButton />
      </Spacing>

      <Spacing>
        <EducationEvents events={events} />
      </Spacing>

      {cards && cards.length > 0 && (
        <Spacing>
          <ApplicationCards tools={cards} count={applicationsCount} />
        </Spacing>
      )}

      {manuals && manuals.length > 0 && (
        <Spacing>
          <StylistTools tools={manuals} count={toolsCount} />
        </Spacing>
      )}
    </>
  );
}
