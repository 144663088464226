export function sortByCreatedAt(items) {
  return items.sort((a, b) => {
    let aDate = new Date(a.createdAt);
    let bDate = new Date(b.createdAt);
    return bDate - aDate;
  });
}

export function sortByDate(items, ascending = false, field = 'date') {
  return items.sort((a, b) => {
    let aDate = new Date(a[field]);
    let bDate = new Date(b[field]);
    if (ascending) return aDate - bDate;
    return bDate - aDate;
  });
}
